@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: "General Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.modalAdd .p-dialog .p-dialog-header{
  background-color: #000000 !important;
}


.modal .p-dialog .p-dialog-content:last-of-type{
  border-radius: 20px;
}