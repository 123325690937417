.top-right {
    z-index: 9999 !important;
  }
  
  .react-confirm-alert-overlay {
    background-color: transparent !important;
  }
  
  .react-confirm-alert-body {
    background-color: #435abd !important;
    color: #fff !important;
  }
  
  .p-dropdown {
    background-color: #435abd !important;
    color: #fff !important;
  }
  
  .p-dropdown .p-dropdown-label.p-placeholder {
    color: #fff !important;
  }
  
  .p-dropdown-panel .p-dropdown-items {
    color: #fff !important;
    border-radius: 10px;
  }
  
  .p-dropdown .p-dropdown-label {
    color: #fff !important;
  }
  
  .p-dropdown-item {
    color: #000 !important;
  }
  
  .p-calendar .p-inputtext {
    background-color: #000000 !important;
    border-color: #435abd !important;
    color: #ffffff !important;
  }

  .
.top-right {
  z-index: 9999 !important;
}

.react-confirm-alert-overlay {
  background-color: transparent !important;
}

.react-confirm-alert-body {
  background-color: #435abd !important;
  color: #fff !important;
}

.p-dropdown {
  background-color: #435abd !important;
  color: #fff !important;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: #fff !important;
}

.p-dropdown-panel .p-dropdown-items {
  color: #fff !important;
  border-radius: 10px;
}

.p-dropdown .p-dropdown-label {
  color: #fff !important;
}

.p-dropdown-item {
  color: #000 !important;
}

.p-calendar .p-inputtext {
  background-color: #000000 !important;
  border-color: #435abd !important;
  color: #ffffff !important;
}

.p-dialog-draggable .p-dialog-header{
  background-color: #435abd !important;
  color: #fff !important;
}
.p-dialog .p-dialog-content:last-of-type{
  background-color: #000000 !important;
  color: #fff !important;
}